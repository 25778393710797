import ArrowDown from '$icons/arrow-down.svg';
import Checkmark from '$icons/checkmark.svg';
import { Icon, InputField } from '$shared/components';
import { useTheme } from '@emotion/react';
import { useId } from '@radix-ui/react-id';
import { useCombobox } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import React, { useRef } from 'react';
import { AnimatedAutoHeight } from '~/shared/components/AnimatedAutoHeight/AnimatedAutoHeight';
import { Option } from '.';
import {
    StyledIcon,
    StyledOptions,
    StyledOptionsWrapper,
    StyledSelectedOptions,
    StyledSelector,
} from './styled';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type SelectorOption = {
    value: string;
    title?: string;
    disabled?: boolean;
};

export type SelectorProps = InputProps & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * The selected Option
     */
    value?: string;

    /**
     *
     */
    options: SelectorOption[];
    onChangeHandler?: (option: SelectorOption | undefined | null) => void;

    /**
     * Set max height limit for dropdown.
     */
    maxHeight?: number;
};

export const SelectorToRender = React.forwardRef<HTMLInputElement, SelectorProps>(
    ({ options, onChangeHandler, maxHeight, onClick, ...rest }, ref) => {
        const id = useId(rest?.id);
        const {
            isOpen,
            getToggleButtonProps,
            getLabelProps,
            getInputProps,
            getMenuProps,
            highlightedIndex,
            getItemProps,
            closeMenu,
            openMenu,
            selectedItem,
        } = useCombobox<SelectorOption>({
            items: options,
            id: id,
            itemToString: (item) => item?.value || '',
            onSelectedItemChange: ({ selectedItem }) => {
                onChangeHandler && onChangeHandler(selectedItem);
                closeMenu();
            },
        });
        const listRef = useRef<HTMLDivElement>(null);

        const theme = useTheme();

        const inputProps = getInputProps({
            ...rest,
            ref,
            readOnly: true,
            type: 'text',
            onClick: (event) => {
                if (rest.disabled) return;
                onClick && onClick(event);
                isOpen ? closeMenu() : openMenu();
            },
            onKeyDown: (event) => {
                if (event.nativeEvent.code == 'Space') {
                    event?.nativeEvent?.preventDefault();

                    if (!isOpen) {
                        openMenu();
                        const ref = listRef?.current;
                        if (ref) {
                            ref?.focus();
                        }
                    } else {
                        closeMenu();
                        (event?.target as HTMLInputElement)?.focus();
                    }
                }
            },
        });

        const openIcon = (
            <StyledIcon isOpen={isOpen}>
                <Icon>
                    <ArrowDown aria-hidden="true" />
                </Icon>
            </StyledIcon>
        );

        return (
            <StyledSelector disabled={rest.disabled ?? false}>
                <InputField
                    {...inputProps}
                    isActive={isOpen}
                    append={openIcon}
                    {...getToggleButtonProps()}
                    {...getLabelProps()}
                >
                    <StyledOptionsWrapper ref={listRef}>
                        <AnimatedAutoHeight maxHeight={maxHeight} {...getMenuProps()}>
                            <AnimatePresence>
                                {isOpen ? (
                                    <StyledOptions
                                        initial={{
                                            opacity: 0,
                                        }}
                                        animate={{
                                            opacity: 1,
                                        }}
                                        transition={{
                                            ...theme.animations.springDefault,
                                        }}
                                    >
                                        {options.map((item, index) => (
                                            <div key={item.value} style={{ position: 'relative' }}>
                                                <Option
                                                    selected={highlightedIndex === index}
                                                    {...item}
                                                    {...getItemProps({
                                                        item,
                                                        disabled: item.disabled,
                                                        index,
                                                    })}
                                                >
                                                    {item.title || item.value}{' '}
                                                    {selectedItem?.value === item.value ? (
                                                        <Icon size="md">
                                                            <Checkmark />
                                                        </Icon>
                                                    ) : null}
                                                </Option>
                                                <AnimatePresence>
                                                    {highlightedIndex === index ? (
                                                        <StyledSelectedOptions
                                                            initial={{
                                                                opacity: 0,
                                                                transform:
                                                                    'translate3d(0, -1rem, 0), scale3d(1, 1, 1)',
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                                transform:
                                                                    'translate3d(0, 0rem, 0), scale3d(1, 1, 1)',
                                                            }}
                                                            transition={{
                                                                type: 'spring',
                                                                stiffness: 300,
                                                                damping: 30,
                                                            }}
                                                            layoutId="selected"
                                                        ></StyledSelectedOptions>
                                                    ) : null}
                                                </AnimatePresence>
                                            </div>
                                        ))}
                                    </StyledOptions>
                                ) : null}
                            </AnimatePresence>
                        </AnimatedAutoHeight>
                    </StyledOptionsWrapper>
                </InputField>
            </StyledSelector>
        );
    },
);
export const Selector = React.forwardRef<HTMLInputElement, SelectorProps>(({ ...rest }, ref) => {
    const [isClientSide, setIsClientSide] = React.useState(false);
    React.useEffect(() => {
        setIsClientSide(true);
    }, []);

    if (!isClientSide || ref === undefined) return null;

    return <SelectorToRender {...rest} ref={ref} />;
});

export default Selector;
